import React from "react";
import { Col, Container, Form, Row, Button } from "react-bootstrap";

const Password = _ => {
  return (
    <>
      <Container>
        <Row>
          <Col>
            <h5>Falta pouco!</h5>

            <p>Dica: digite uma senha de no mínimo 6 caracteres</p>
            <Form>
              <Form.Group controlId="password">
                <Form.Label>Digite uma senha</Form.Label>
                <Form.Control type="password" placeholder="Digite uma senha" name="password"/>
              </Form.Group>

              <Form.Group controlId="confirmation-password">
                <Form.Label>Confirme sua senha</Form.Label>
                <Form.Control type="text" placeholder="Confirme sua senha" name="confimation-password"/>
              </Form.Group>

              <Button variant="primary" className="p-2">Cadastrar</Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default Password;
