import { useEffect, useState } from "react";
import { Button, Container, Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router";

// services
import MeService from "../../../services/me";

// types
import ClientAddressType from "../../../types/ClientAddress.types";

// styles
import "./style.scss";

const Address = () => {
  const [addresses, setAddresses] = useState<ClientAddressType[]>([]);

  const user = sessionStorage.getItem("user");

  const navigate = useNavigate();

  // fech client
  useEffect(() => {
    if (user) {
      MeService.list().then((res) => setAddresses(res.data));
    }
  }, [user]);

  // handleEdit
  const handleEdit = (id) => {
    if (id) {
      navigate("/client/edit-address/" + id);
    }
  };

  return (
    <Container className="container-content">
      <Row>
        <Col md={12}>
          <h2>Meus Endereços</h2>
        </Col>
      </Row>

      <Row>
        <Col md={8} className="d-flex my-2">
          <p>
            Confira todos os seus endereços cadastrados, para realizar entrega
          </p>
        </Col>
        <Col md={4} className="d-flex my-2 justify-content-end">
          <Button onClick={() => navigate("/client/add-address")}>
            Adicionar Endereço
          </Button>
        </Col>
      </Row>

      <Row>
        <Col md={12}>
          {addresses.length >= 1 && (
            <ul className="addresses-list">
              {addresses.map((add) => {
                return (
                  <li className="shadow-sm" key={add.id}>
                    <strong className="title">
                      {add.name}, {add.number}
                    </strong>
                    <span>CEP: {add.cep} - Creche Pingo de Gente</span>
                    <span>{add.neighborhood}, Bauru/SP</span>

                    <div>
                      <Button
                        size="sm"
                        variant="info"
                        className="float-end"
                        onClick={() => handleEdit(add.id)}
                      >
                        Editar
                      </Button>
                    </div>
                  </li>
                );
              })}
            </ul>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default Address;
