import * as yup from 'yup';
import { pt } from 'yup-locale-pt';

yup.setLocale(pt);

const withPasswordSchema = yup.object().shape({
  name: yup.string().required("Nome é obrigatório").max(128).label("Nome"),
  phone: yup.string().required().max(128).label("Celular"),
  email: yup.string().required().max(128).label("Email"),
  password: yup.string().required().min(6).max(128).label("Senha"),
});

const withoutPasswordSchema = yup.object().shape({
  name: yup.string().required("Nome é obrigatório").max(128).label("Nome"),
  phone: yup.string().required().max(128).label("Celular"),
  email: yup.string().required().max(128).label("Email"),
});

const clientValidation = {
  withoutPasswordSchema,
  withPasswordSchema
}

export default clientValidation;
