export function setStoreRegister(store) {
  return {
    type: "SET_STORE_REGISTER",
    payload: store,
  };
}

export function getRegisterStore(store) {
  return {
    type: "GET_STORE_REGISTER",
    payload: store,
  };
}
