import { Breadcrumb, Col, Container, Row } from "react-bootstrap";

const Success = () => {
  return (
    <>
      <Container fluid className="breadcrumb-container">
        <Container>
          <Breadcrumb>
            <Breadcrumb.Item href="/">Início</Breadcrumb.Item>
            <Breadcrumb.Item href="/create-account">
              Criar conta
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Sucesso</Breadcrumb.Item>
          </Breadcrumb>
        </Container>
      </Container>

      <Container>
        <div className="content">
          <Row>
            <Col sm={12} md={12}>
              <h1>Sucesso</h1>
              <p>Cadastrado realizado com sucesso!</p>
              <p>Faça o login para acessar a sua conta.</p>
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
};

export default Success;
