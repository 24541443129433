import { Link } from "react-router-dom";

const HeaderHome = ({ pathname }) => {
  return (
    <ul>
      <li className={pathname === "/" ? "active" : ""}>
        <Link to="/">Todos</Link>
      </li>
      <li className={pathname === "/categories" ? "active" : ""}>
        <Link to="/categories">Categorias</Link>
      </li>
    </ul>
  );
};

export default HeaderHome;
