import { Link } from "react-router-dom";

// styles
import "./style.scss";

const StoreCategory = ({ category }) => {
  const icon = (category) => {
    const image = JSON.parse(category?.image);
    return <img src={image.path} alt={category.name} />;
  };

  return (
    <Link
      className="store-category-link"
      to={{
        pathname: "/",
        search: `?category=${category.id}`,
      }}
    >
      <div className="store-category-content">
        {category?.image ? icon(category) : ''}
        <span className="store-category-name">{category.name}</span>
      </div>
    </Link>
  );
};

export default StoreCategory;
