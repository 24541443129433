import Store from "./Store";
import Checkout from "./Checkout";
import Finished from "./Finished";
import Make from "./Make";
import Progress from "./Progress";
import Cart from './Cart';

const Delivery = {
  Store,
  Checkout,
  Finished,
  Make,
  Progress,
  Cart,
};

export default Delivery;
