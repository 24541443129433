import { useState, useEffect } from "react";
import { Col, Container, Row, Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import ReactGA from "react-ga";

// estilos
import "./style.scss";

// componentes
import Item from "./Item";
import Make from "./Make";
import SameStore from "./SameStore";

import HeaderStore from "../../components/HeaderStore";
import HeaderCategories from "../../components/HeaderCategories";

// services
import companiesService from "../../services/companies";
import categoryService from "../../services/categories";

const Store = () => {
  const { id, slug } = useParams();

  const [categories, setCategories] = useState({});
  const [show, setShow] = useState(false);
  const [showSameStore, setShowSameStore] = useState(false);
  const [store, setStore] = useState({});
  const [productCheckout, setProductCheckout] = useState({});

  let sessionStore = JSON.parse(sessionStorage.getItem("store"));
  const sessionStoreId = sessionStorage.getItem("storeId");

  const handleClose = () => setShow(false);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const handleOpen = (product) => {
    // verifica módulo carrinho
    if (store.store) {
      if (sessionStore && sessionStore.id !== product.company_id) {
        setShowSameStore(true);
      }

      setProductCheckout(product);
      setShow(true);
    }
  };

  useEffect(() => {
    if (id) {
      companiesService.findById(id).then((res) => setStore(res.data));
      categoryService.products(id).then((res) => setCategories(res.data));

      if (!sessionStoreId) {
        sessionStorage.setItem("storeId", id);
      }
    }

    if (slug) {
      companiesService
        .findBySlug(slug)
        .then((resCompany) => {
          if (resCompany.data) {
            setStore(resCompany.data);
            sessionStorage.setItem("storeId", resCompany.data.id);

            categoryService.products(resCompany.data.id).then((res) => {
              setCategories(res.data);
            });
          }
        })
        .catch((err) => console.log(err));
    }
  }, [id, slug, sessionStoreId]);

  return (
    <>
      <HeaderStore store={store} />
      <HeaderCategories company_id={store.id} />

      <Container>
        <SameStore show={showSameStore} />

        <Row>
          <Col md={12} xs={12}>
            {categories.length > 0 && (
              <ul className="product-items">
                {categories.map((category) => {
                  const products = category.products;
                  const listProducts = products.map((product) => {
                    return <Item product={product} handleOpen={handleOpen} />;
                  });

                  return (
                    <>
                      <li
                        className={"category-title " + category.id}
                        key={"category_" + category.id}
                      >
                        {category.name}
                      </li>
                      {listProducts}
                    </>
                  );
                })}
              </ul>
            )}
          </Col>
        </Row>

        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>{productCheckout.name}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Make product={productCheckout} />
          </Modal.Body>
        </Modal>
      </Container>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    store: state.store.store,
  };
};

export default connect(mapStateToProps)(Store);
