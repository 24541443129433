import Account from "./Account";
import Address from "./Address/Address";
import FormAddress from "./Address/FormAddress";
import Login from "./Login";
import Password from "./Password";
import Order from "./Order";
import Profile from "./Profile";
import Success from "./Success";

const Client = {
  Address,
  Account,
  FormAddress,
  Login,
  Password,
  Order,
  Profile,
  Success,
};

export default Client;
