import { useState } from "react";
import { Button, Container, Navbar, FormControl, Dropdown } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

// images
import Ranggo from "./ranggo.png";
import Bag from "./bag.svg";

// styles
import "./style.scss";

const Header = (props) => {
  const authenticate = sessionStorage.getItem("authenticate");
  const user = JSON.parse(sessionStorage.getItem("user"));
  const [store, setStore] = useState("");
  const navigate = useNavigate();

  const logout = () => {
    sessionStorage.removeItem("authenticate");
    sessionStorage.removeItem("user");
    sessionStorage.removeItem("cart");
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("store");

    navigate("/login");
  };

  const handleStore = (event) => {
    setStore(event.target.value);
  };

  return (
    <Container fluid>
      <Navbar expand="lg" className="bg-navbar">
        <Container>
          <Navbar.Brand href="/">
            <img src={Ranggo} alt="" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="navbarScroll">
            <div className="d-flex">
              <FormControl
                type="search"
                placeholder="Procurar restaurantes..."
                className="me-2"
                aria-label="Procurar restaurantes..."
                onChange={handleStore}
              />

              <Button onClick={() => props.handleFindStore(store)}>
                Buscar
              </Button>

              <Link to="/delivery/checkout" className="basket-link">
                <img src={Bag} alt="cesta" />
              </Link>
            </div>

            <div className="header-actions">
              {!authenticate && (
                <ul className="account-actions">
                  <li>
                    <Link to="/login">Entrar</Link>
                  </li>
                  <li>
                    <Link to="create-account">Criar conta</Link>
                  </li>
                </ul>
              )}

              {authenticate && (
                <div className="account-authenticate">
                  <div className="welcome-login">
                    Olá, {user.name}! | {" "}
                  </div>

                  <div>
                    <Dropdown>
                      <Dropdown.Toggle>
                        Meus Dados
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item href="/client/profile">Conta</Dropdown.Item>
                        <Dropdown.Item href="/client/address">Endereços</Dropdown.Item>
                        <Dropdown.Item href="/client/orders">Pedidos</Dropdown.Item>
                        <Dropdown.Item href="#" onClick={() => logout()}>Sair</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              )}
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </Container>
  );
};

export default Header;
