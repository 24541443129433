import Company from "./Company";
import User from "./User";
import Success from "./Success";

const Partner = {
  Company,
  User,
  Success,
};

export default Partner;
