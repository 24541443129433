import { useFormik } from "formik";
import { useState, useEffect } from "react";
import {
  Alert,
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Col,
  Container,
  Form,
  Row,
} from "react-bootstrap";

// styles
import "./Profile.scss";

// services
import ClientService from "../../services/clients";
import MeService from "../../services/me";

// scheme validation
import clientValidationSchema from "../../validation/client";

type ClientType = {
  id: number;
  name: string;
  phone: string;
  email: string;
};

const Profile = () => {
  const [clientForm, setClientForm] = useState<ClientType>({
    id: 0,
    name: "",
    phone: "",
    email: "",
  });

  const [messageSuccess, setMessageSuccess] = useState(false);

  const user = sessionStorage.getItem("user");

  const formik = useFormik({
    initialValues: clientForm,
    onSubmit: (values) => {
      let { id } = values;
      let dataClient = {
        name: values.name,
        phone: values.phone,
        email: values.email,
      };

      ClientService.update(id, dataClient).then((res) => {
        if (res.status === 200) {
          setMessageSuccess(true);
        }
      });
    },
    validationSchema: clientValidationSchema.withoutPasswordSchema,
    enableReinitialize: true,
  });

  // fech client
  useEffect(() => {
    if (user) {
      MeService.me().then((res) => {
        setClientForm({
          id: res.data.id,
          name: res.data.name,
          phone: res.data.phone,
          email: res.data.email,
        });
      });
    }
  }, [user]);

  return (
    <>
      <Container fluid className="breadcrumb-container">
        <Container>
          <Breadcrumb>
            <BreadcrumbItem>Início</BreadcrumbItem>
            <BreadcrumbItem active>Meus Dados</BreadcrumbItem>
          </Breadcrumb>
        </Container>
      </Container>

      <Container className="container-content">
        <Row>
          <Col md={12}>
            <h2>Meus Dados</h2>

            {messageSuccess && (
              <Alert
                variant="primary"
                onClose={() => setMessageSuccess(false)}
                dismissible
              >
                Dados atualizados com sucesso!
              </Alert>
            )}

            <Form onSubmit={formik.handleSubmit}>
              <Form.Group className="mb-3">
                <Form.Label>Nome</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Nome"
                  name="name"
                  onChange={formik.handleChange}
                  value={formik.values.name}
                  isInvalid={!!formik.errors.name}
                  maxLength={120}
                />

                <Form.Control.Feedback type="invalid">
                  {formik.errors.name}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Email"
                  name="email"
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  // isInvalid={!!formik.errors.email}
                  maxLength={120}
                />

                <Form.Control.Feedback type="invalid">
                  {formik.errors.email}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Telefone</Form.Label>
                <Form.Control
                  type="text"
                  name="phone"
                  // mask="(99) 99999-9999"
                  className="form-control"
                  onChange={formik.handleChange}
                  value={formik.values.phone}
                  // isInvalid={!!formik.errors.phone}
                />

                <Form.Control.Feedback type="invalid">
                  {formik.errors.phone}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="mb-3">
                <Button type="submit" size="lg" className="float-end">
                  Salvar
                </Button>
              </Form.Group>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Profile;
