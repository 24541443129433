import axios from "axios"

const getToken = () => {
  return sessionStorage.getItem("token");
};

export { getToken }

const instance = axios.create({
  timeout: 5000,
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    Authorization: `Bearer ${getToken()}`,
    'Access-Control-Allow-Origin': '*',
  }
});

export default instance;
