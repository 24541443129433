import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

// components
import Layout from "./components/Layout";

// pages
import Home from "./pages/Home";
import Category from "./pages/Home/Category";
import Client from "./pages/Client";
import Delivery from "./pages/Delivery";
import Register from "./pages/Register";
import NotFound from "./pages/NotFound";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Partner from "./pages/Partner";

// services
import { isAuthenticated } from "./services/auth";

const PrivateRoute = ({ children, redirectTo }) => isAuthenticated() ? children : <Navigate to={redirectTo} />;

const AppRoutes = () => {
  return (
    <BrowserRouter>
      <Layout>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/not-found" element={<NotFound />} />
          <Route exact path="/categories" element={<Category />} />

          <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />

          <Route exact path="/register" element={<Register.RegisterForm />} />
          <Route exact path="/register/success" element={<Register.Success />} />

          <Route exact path="/login" element={<Client.Login />} />
          <Route exact path="/password" element={<Client.Password />} />
          <Route exact path="/create-account" element={<Client.Account />} />

          <Route exact path="/success-account" element={<Client.Success />} />

          <Route exact path="/delivery/name/:slug" element={<Delivery.Store />} />
          <Route exact path="/delivery/store/:id" element={<Delivery.Store />} />
          <Route
            exact
            path="/delivery/checkout"
            element={<Delivery.Checkout />}
          />
          <Route exact path="/delivery/make" element={<Delivery.Make />} />

          <Route
            exact
            path="/delivery/finished"
            element={<Delivery.Finished />}
          />

          <Route
            exact
            path="/delivery/progress"
            element={<Delivery.Progress />}
          />

          <Route exact path="/cart" element={<Delivery.Cart />} />

          <Route exact path="/partner" element={<Partner.Company />} />
          <Route exact path="/partner/user" element={<Partner.User />} />
          <Route exact path="/partner/success" element={<Partner.Success />} />

          <Route
            path="/client/address"
            element={
              <PrivateRoute redirectTo="/login">
                <Client.Address />
              </PrivateRoute>
            }
          />

          <Route
            path="/client/add-address"
            element={
              <PrivateRoute redirectTo="/login">
                <Client.FormAddress />
              </PrivateRoute>
            }
          />
          <Route
            path="/client/edit-address/:id"
            element={
              <PrivateRoute redirectTo="/login">
                <Client.FormAddress />
              </PrivateRoute>
            }
          />

          <Route
            path="/client/profile"
            element={
              <PrivateRoute redirectTo="/login">
                <Client.Profile />
              </PrivateRoute>
            }
          />

          <Route
            path="/client/orders"
            element={
              <PrivateRoute redirectTo="/login">
                <Client.Order />
              </PrivateRoute>
            }
          />

        </Routes>
      </Layout>
    </BrowserRouter>
  );
};

export default AppRoutes;
