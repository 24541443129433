import './App.scss';
import AppRoutes from "./Routes";
import ReactGA from 'react-ga';

ReactGA.initialize('G-GDX4C1VMHR');

function App() {
  return (
    <AppRoutes />
  );
}

export default App;
