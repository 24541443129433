export function setStoreDefault(store) {
  return {
    type: 'SET_STORE',
    payload: store,
  }
}

export function getStore(store) {
  return {
    type: 'GET_STORE',
    payload: store,
  }
}
