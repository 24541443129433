const cleanCart = () => {
  sessionStorage.removeItem('store');
  sessionStorage.removeItem('cart');
}

const CartService = {
  cleanCart,
}

export default CartService;
