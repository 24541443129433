import { Breadcrumb, Button, Col, Container, Form, Row } from "react-bootstrap";
import { useFormik } from "formik";
import { useNavigate } from "react-router";
import InputMask from "react-input-mask";
import { connect } from "react-redux";
import * as yup from "yup";

// styles
import "./style.scss";

// actions
import { setStoreRegister } from "../../store/actions/storeRegister";

// services
import companiesService from "../../services/companies";

const Company = ({ storeRegister }) => {
  const navigate = useNavigate();

  const schemaValidation = yup.object().shape({
    name: yup.string().required('Obrigatório').max(128),
    cnpj: yup.string().required('Obrigatório').max(128),
    phone: yup.string().required('Obrigatório').max(128),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      cnpj: "",
      phone: "",
    },
    onSubmit: (values) => {
      companiesService.create(values).then((res) => {
        if (res.status === 200) {
          storeRegister(res.data);
          navigate("/partner/user");
        }
      });
    },
    validationSchema: schemaValidation,
  });

  return (
    <>
      <Container fluid className="breadcrumb-container">
        <Container>
          <Breadcrumb>
            <Breadcrumb.Item href="/">Início</Breadcrumb.Item>
            <Breadcrumb.Item active>Seja parceiro</Breadcrumb.Item>
          </Breadcrumb>
        </Container>
      </Container>

      <Container>
        <div className="content">
          <Row>
            <Col sm={12} lg={12}>
              <h1>Seu restaurante</h1>

              <p>Preencha os dados do seu restaurante é bem simples</p>
            </Col>
          </Row>

          <Row>
            <Col sm={12} lg={12}>
              <Form noValidate onSubmit={formik.handleSubmit}>
                <Form.Group className="mb-3">
                  <Form.Label>Nome <strong>*</strong></Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Nome"
                    name="name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    isInvalid={!!formik.errors.name}
                  />

                  <Form.Control.Feedback type="invalid">
                    {formik.errors.name}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>WhatsApp <strong>*</strong></Form.Label>
                  <InputMask
                    type="text"
                    placeholder="WhatsApp"
                    name="phone"
                    mask="(99) 99999-9999"
                    className="form-control"
                    onChange={formik.handleChange}
                    value={formik.values.phone}
                    isInvalid={!!formik.errors.phone}
                  />

                  {formik.touched.phone && formik.errors.phone ? (
                     <Form.Control.Feedback>{formik.errors.phone}</Form.Control.Feedback>
                  ) : null}
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>CNPJ <strong>*</strong></Form.Label>
                  <InputMask
                    type="text"
                    placeholder="CNPJ"
                    name="cnpj"
                    mask="99.999.999/9999-99"
                    className="form-control"
                    onChange={formik.handleChange}
                    value={formik.values.cnpj}
                  />

                  {formik.touched.cnpj && formik.errors.cnpj ? (
                    <div>{formik.errors.cnpj}</div>
                  ) : null}
                </Form.Group>

                <div className="text-center mt-2">
                  <Button
                    variant="primary"
                    size="xl"
                    type="submit"
                  >
                    Vamos lá!!!
                  </Button>
                </div>
              </Form>
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    storeRegister: state.storeRegister,
  };
};

const mapDispatchToProp = (dispatch) => {
  return {
    storeRegister(loja) {
      const action = setStoreRegister(loja);
      dispatch(action);
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProp)(Company);
