import axios from "axios";
import { useFormik } from "formik";
import { useState, useEffect } from "react";
import { Breadcrumb, BreadcrumbItem, Button, Col, Container, Form, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router";

// services
import meService from "../../../services/me";
import locationService from "../../../services/location";

// schema
import addressValidation from "../../../validation/address";

type addressType = {
  cep: string;
  logradouro: string;
  bairro: string;
  localidade: string;
  uf: string;
  numero: string;
};

const FormAddress = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [cep, setCep] = useState("");
  const [address, setAddress] = useState<addressType>({
    cep: "",
    logradouro: "",
    bairro: "",
    localidade: "",
    uf: "",
    numero: "",
  });

  // useState para estados
  const [stateOptions, setStateOptions] = useState([]);

  // useState para cidades
  const [citiesOptions, setCitiesOptions] = useState([]);

  const handleChangeCep = (event) => {
    setCep(event.target.value.replace(/(\d{5})(\d{3})/g, "$1-$2"));
  };

  // carrega as cidades pelo estado selecionado
  const handleChangeState = (event) => {
    const stateId = event.target.value;

    locationService.cityByState(stateId)
      .then(res => setCitiesOptions(res.data))
      .catch(err => console.error(err))
  }

  // busca o endereço pelo
  useEffect(() => {
    if (cep.length > 0) {
      const cepFormatted = cep.replace("-", "");

      if (cepFormatted.length === 8) {
        axios.get(`https://viacep.com.br/ws/${cepFormatted}/json`).then((res) => {
          setAddress({
            cep: res.data.cep,
            logradouro: res.data.logradouro,
            bairro: res.data.bairro,
            localidade: res.data.localidade,
            uf: '',
            numero: '',
          })
        });
      }
    }
  }, [cep]);

  // carrega todos os estados
  useEffect(() => {
    locationService
      .states()
      .then((res) => setStateOptions(res.data))
      .catch((err) => console.error(err));
  }, []);

  // carrega a informação para edição
  useEffect(() => {
    meService
      .findAddressById(id)
      .then((res) =>
        setAddress({
          cep: res.data.cep,
          logradouro: res.data.address,
          numero: res.data.number,
          bairro: res.data.neighborhood,
          localidade: res.data.cityId,
          uf: res.data.stateId,
        })
      )
      .catch((err) => console.log(err));
  }, [id]);

  // inicialização do formik
  const formik = useFormik({
    initialValues: address,
    onSubmit: (values) => {
      meService.create(values).then((res) => {
        if (res.status === 200) {
          navigate("/client/address");
        }
      });
    },
    enableReinitialize: true,
    validationSchema: addressValidation,
  });

  return (
    <>
      <Container fluid className="breadcrumb-container">
        <Container>
          <Breadcrumb>
            <BreadcrumbItem href="/">Início</BreadcrumbItem>
            <BreadcrumbItem href="/client/profile">Meus Dados</BreadcrumbItem>
            <BreadcrumbItem href="/client/address">Endereços</BreadcrumbItem>
            <BreadcrumbItem active>Endereço</BreadcrumbItem>
          </Breadcrumb>
        </Container>
      </Container>

      <Container className="container-content">
        <Row>
          <Col>
            <h2>{id ? "Editar" : "Adicionar"} um endereço</h2>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form onSubmit={formik.handleSubmit}>
              <Form.Group className="mb-3" controlId="formAddress">
                <Form.Label>CEP*</Form.Label>

                <Form.Control
                  type="text"
                  name="cep"
                  value={formik?.values?.cep}
                  onChange={(e) => {
                    formik.handleChange(e)
                    handleChangeCep(e)
                  }}
                  maxLength={9}
                  isInvalid={!!formik.errors.cep}
                />

                {formik.errors.cep && formik.touched.cep ? (
                  <Form.Control.Feedback type="invalid">{formik.errors.cep}</Form.Control.Feedback>
                ) : null}
              </Form.Group>

              {address && (
                <>
                  <Form.Group className="mb-3" controlId="formAddress">
                    <Form.Label>Logradouro*</Form.Label>
                    <Form.Control
                      type="text"
                      name="logradouro"
                      value={formik?.values?.logradouro}
                      onChange={formik.handleChange}
                      maxLength={128}
                      readOnly
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formAddress">
                    <Form.Label>Número*</Form.Label>
                    <Form.Control
                      type="text"
                      name="numero"
                      value={formik?.values?.numero}
                      onChange={formik.handleChange}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formAddress">
                    <Form.Label>Bairro*</Form.Label>
                    <Form.Control
                      type="text"
                      name="bairro"
                      value={formik?.values?.bairro}
                      onChange={formik.handleChange}
                      readOnly
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formAddress">
                    <Form.Label>Estado*</Form.Label>
                    <Form.Select name="uf" onChange={(e) => { handleChangeState(e); formik.handleChange(e)}} value={formik?.values?.uf}>
                      <option>Selecione</option>
                      {stateOptions.length > 0 &&
                        stateOptions.map((state: { id: number; name: string }, index) => {
                          return (
                            <option key={index} value={state.id}>
                              {state.name}
                            </option>
                          );
                        })}
                    </Form.Select>
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formAddress">
                    <Form.Label>Cidade*</Form.Label>
                    <Form.Select name="localidade" onChange={formik.handleChange} value={formik?.values?.localidade}>
                      <option>Selecione</option>
                      {citiesOptions.length > 0 &&
                        citiesOptions.map((city: {id: number, name: string}, index) => {
                          return (
                            <option key={index} value={city.id}>
                              {city.name}
                            </option>
                          )
                        })
                      }
                    </Form.Select>
                  </Form.Group>

                  <div className="d-flex justify-content-end">
                    <Button variant="primary" type="submit">
                      Salvar
                    </Button>
                  </div>
                </>
              )}
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default FormAddress;
