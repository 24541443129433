import instance from "../axiosInstance";

const auth = (email, password) => {
  return instance.post(
    "/me/auth",
    {
      email,
      password,
    },
    {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Access-Control-Allow-Origin": "*",
      },
    }
  );
};

const getToken = () => {
  return sessionStorage.getItem("token");
};

const create = (data) => {
  return instance.post(
    "/me/address",
    {
      name: data.logradouro,
      address: data.logradouro,
      clientId: 1,
      cep: data.cep,
      cityId: +data.localidade,
      stateId: +data.uf,
      neighborhood: data.bairro,
      number: data.numero,
    },
    {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Access-Control-Allow-Origin": "*",
      },
    }
  );
};

const facebookRegister = async (name: string, email: string, facebookUserId: string) => {
  return await instance.post("/clients/register-facebook", {
    name,
    email,
    facebookUserId,
  });
};

const list = () => {
  return instance.get("/me/address", {
    headers: {
      Authorization: `Bearer ${getToken()}`,
      "Access-Control-Allow-Origin": "*",
    },
  });
};

const me = () => {
  return instance.get("/me", {
    headers: {
      Authorization: `Bearer ${getToken()}`,
      "Access-Control-Allow-Origin": "*",
    },
  });
}

const findAddressById = async (id) => await instance.get(`/me/address/${id}`)

const orderList = () => {
  return instance.get('/me/orders', {
    headers: {
      Authorization: `Bearer ${getToken()}`,
      "Access-Control-Allow-Origin": "*",
    }
  })
}

const notificationOrder = () => instance.get("/test-socket");

const MeService = {
  auth,
  create,
  facebookRegister,
  list,
  me,
  findAddressById,
  notificationOrder,
  orderList,
};

export default MeService;
