import * as yup from 'yup';
import { pt } from 'yup-locale-pt';

yup.setLocale(pt);

const addressValidation = yup.object().shape({
  cep: yup.string().required().label("CEP"),
  logradouro: yup.string().required().max(128).label("Logradouro"),
});

export default addressValidation
