import { createStore, combineReducers } from "redux";

import storeReducer from "./reducers/storeReducer";
import storeRegisterReducer from "./reducers/storeRegisterReducer";

const reducers = combineReducers({
  store: storeReducer,
  storeRegister: storeRegisterReducer,
});

const storeConfig = () => {
  return createStore(reducers, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());
};

export default storeConfig;
