import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import "./style.scss";
import whatsApp from "./whatsapp.svg";

const HeaderStore = (props) => {
  const phone = props.store.phone

  return (
    <Container fluid>
      <Row className="m-0">
        <Col className="p-0">
          <div className="header-store">
            <Container>
              <Row>
                <Col>
                  <div>
                    <span className="header-store-title">
                      {props.store.name}
                    </span>

                    { phone && (
                      <span className="header-store-whatsapp">
                        <a href={`https://wa.me/55${phone.replace(/\D/g, '')}?text=Oi`}>
                          <img src={whatsApp} alt="WhatsApp" className="whatsapp-logo"/> {props.store.phone}
                        </a>
                      </span>
                    )}
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default HeaderStore;
