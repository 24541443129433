import instance, { getToken } from "../axiosInstance";

type TOrder = {
  clientId: number;
  companyId: number;
  paymethodId: number;
};

const create = (order: TOrder) =>
  instance.post("/me/order", order, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
      "Access-Control-Allow-Origin": "*",
    },
  });

const list = () => instance.get("/orders");

const OrderService = {
  create,
  list,
};

export default OrderService;
