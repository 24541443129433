import { Col, Row, Container } from "react-bootstrap";

const Success = () => {
  return (
    <>
      <Container>
        <div className="content">
          <Row>
            <Col>
              <h3 className="mt-4">Cadastro com sucesso!</h3>
            </Col>
          </Row>
        </div>
      </Container>
    </>
  )
}

export default Success;
