import instance from "../axiosInstance"

const geAuthenticate = () => {
  if (sessionStorage.getItem("authenticate")) {
    return true;
  };

  return false;
}

const create = (data) => {
  return instance.post("/clients", data);
}

const facebookRegister = async (name, email, facebookUserId) => {
  await instance.post("/clients/register-facebook", {
    name,
    email,
    facebookUserId,
  });
};

const update = (id, data) => {
  return instance.put("/clients/" + id, data, {
    headers: {
      'Authorization': `Bearer ${getToken()}`,
      'Access-Control-Allow-Origin': '*',
    }
  })
}

const getToken = () => {
  return sessionStorage.getItem('token');
}

const getClient = (id) => {
  return instance.get('/clients/' + id, {
    headers: {
      'Authorization': `Bearer ${getToken()}`,
      'Access-Control-Allow-Origin': '*',
    }
  })
}

const ClientService = {
  create,
  facebookRegister,
  geAuthenticate,
  getClient,
  update,
}

export default ClientService
