import { useState, useEffect } from "react";
import { Container, Form } from "react-bootstrap";
import { scroller } from "react-scroll";
import categoryService from "../../services/categories";
import "./style.scss";

const HeaderCategories = ({ company_id }) => {
  const [categories, setCategories] = useState({});

  useEffect(() => {
    if (company_id) {
      categoryService
        .findByCompany(company_id)
        .then((res) => setCategories(res.data))
        .catch((error) => console.error(error));
    }
  }, [company_id]);

  const handleChangeSelect = (event) => {
    scroller.scrollTo("category_" + event.target.value, {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
    });
  };

  return (
    <div className="header-categories">
      <div className="header-container">
        <Container>
          <Form>
            <Form.Control
              as="select"
              onChange={(event) => handleChangeSelect(event)}
            >
              <option key={0} value={0}>
                Categorias
              </option>
              {categories.length > 0 &&
                categories.map((e, key) => {
                  return (
                    <option key={key} value={e.id}>
                      {e.name}
                    </option>
                  );
                })}
            </Form.Control>
          </Form>
        </Container>
      </div>
    </div>
  );
};

export default HeaderCategories;
