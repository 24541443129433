import React from "react";

const Item = ({ product, handleOpen }) => {
  const imageJSON = product.image ? JSON.parse(product.image) : null;

  return (
    <li
      className="product-item"
      key={product.id + Math.random()}
      onClick={() => handleOpen(product)}
    >
      {imageJSON?.path && (
        <div className="product-image">
          {product.image && (
            <img src={imageJSON.path} alt={product.name} className="rounded" />
          )}
        </div>
      )}

      <div className="product-text">
        <p className="product-title">{product.name}</p>
        <p className="product-description">{product.description}</p>

        <div className="product-price-container justify-content-end">
          <span className="product-price-value">R$ {product.price}</span>
        </div>
      </div>
    </li>
  );
};

export default Item;
