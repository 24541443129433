import { Breadcrumb, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

// styles
import "./style.scss";

const Success = () => {
  return (
    <>
      <Container fluid className="breadcrumb-container">
        <Container>
          <Breadcrumb>
            <Breadcrumb.Item href="/">Início</Breadcrumb.Item>
            <Breadcrumb.Item active>Seja parceiro</Breadcrumb.Item>
          </Breadcrumb>
        </Container>
      </Container>

      <Container>
        <div className="content">
          <Row>
            <Col sm={12} lg={12}>
              <h1>Tudo pronto!</h1>
            </Col>
          </Row>

          <Row>
            <Col sm={12} lg={12}>
              <p>Finalizamos, agora você faz parte do Ranggo!</p>
              <Link
                to={{ pathname: "https://gestor.ranggo.app" }}
                target="_blank"
              >
                Clique aqui para cadastrar os produtos
              </Link>
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
};

export default Success;
