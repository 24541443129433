import React, { useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { useNavigate } from "react-router";
import ClientService from "../../services/clients";

const RegisterForm = () => {
  const navigate = useNavigate();
  const [errors, setErrors] = useState(null);

  const [clientData, setClientData] = useState({
    name: "",
    email: "",
    phone: "",
    password: "",
    cpf: "",
  });

  const handleRegister = () => {
    ClientService.create(clientData)
      .then((_) => {
        navigate("/register/success");
      })
      .catch((err) => {
        setErrors(err.response.data.errors);
        console.log(err);
      });
  };

  const handleChange = (e) => {
    setClientData({ ...clientData, [e.target.name]: e.target.value });
  };

  return (
    <>
      <Container>
        <div className="content">
          <Row>
            <Col>
              <h3 className="mt-4">Cadatre-se agora!</h3>

              <p className="mb-4">
                Aproveite as ofertas e a praticidade do{" "}
                {process.env.REACT_APP_NAME}!
              </p>

              {errors && (
                <div className="alert alert-danger" role="alert">
                  <ul>
                    {errors.map((item, key) => {
                      return <li key={key}>{item.msg}</li>;
                    })}
                  </ul>
                </div>
              )}

              <Form>
                <Form.Group className="mb-3" controlId="name">
                  <Form.Label>Nomes</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Nome"
                    name="name"
                    value={clientData.name}
                    onChange={(e) => handleChange(e)}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="email">
                  <Form.Label>E-mail</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="E-mail"
                    name="email"
                    onChange={(e) => handleChange(e)}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="phone">
                  <Form.Label>Telefone</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Telefone"
                    name="phone"
                    onChange={(e) => handleChange(e)}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="cpf">
                  <Form.Label>CPF</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="CPF"
                    name="cpf"
                    onChange={(e) => handleChange(e)}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="password">
                  <Form.Label>Senha</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Senha"
                    name="password"
                    onChange={(e) => handleChange(e)}
                  />
                </Form.Group>

                <Form.Group className="mb-3 d-flex justify-content-end">
                  <Button
                    variant="primary"
                    size="lg"
                    onClick={() => handleRegister()}
                  >
                    Cadastrar
                  </Button>
                </Form.Group>
              </Form>
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
};

export default RegisterForm;
