import React from "react";
import "./style.scss";

export default function ProductItem(props) {
  const menu = props.items.map((item) => (
    <>
      <li className="product-item">
        <div className="product-image">
          <img src={item.image} alt={item.name} />
        </div>

        <div className="product-text">
          <p className="product-title">{item.name}</p>
          <p className="product-description">{item.description}</p>

          <div className="product-price-container">
            <span className="product-price-description">A partir de </span>
            <span className="product-price-value">R$ {item.price}</span>
          </div>
        </div>
      </li>
    </>
  ))

  return (
    <ul>
      { menu }
    </ul>
  );
}
