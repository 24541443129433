import { Col, Container, Row } from "react-bootstrap"
import { Link } from "react-router-dom";
import "./style.scss"

const Footer = () => {
  return (
    <Container fluid className="footer">
      <Container>
        <Row>
          <Col sm={4} lg={4}>
            <h2>Social</h2>

            <ul>
              <li>
                <a href="https://www.instagram.com/ranggo.app" target="_blank" rel="noreferrer">Instagram</a>
              </li>
              <li>
                <a href="https://www.facebook.com/ranggo.oficial" target="_blank" rel="noreferrer">Facebook</a>
              </li>
            </ul>
          </Col>

          <Col sm={4} lg={4}>
            <h2>Seja Parceiro</h2>

            <ul>
              <li>
                <Link to="/partner">Venha ser nosso parceiro</Link>
              </li>
            </ul>
          </Col>

          <Col sm={4} lg={4}>
            <h2>Somos Legais</h2>

            <ul>
              <li>
                <Link to="/privacy-policy">Termo de uso</Link>
              </li>
            </ul>
          </Col>
        </Row>

        <Row>
          <Col md={12} className="d-flex justify-content-center">
            <p>Desenvolvido por: Lumia Solutions</p>
          </Col>
        </Row>
      </Container>
    </Container>
  )
}

export default Footer;
