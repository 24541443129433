import { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
} from "react-bootstrap";

// styles
import "./Order.scss";

// services
import MeService from "../../services/me";

export default function Order() {
  const [orders, setOrders] = useState({});

  useEffect(() => {
    MeService.orderList().then((res) => setOrders(res.data));
  }, []);

  return (
    <>
      <Container fluid className="breadcrumb-container">
        <Container>
          <Breadcrumb>
            <BreadcrumbItem>Início</BreadcrumbItem>
            <BreadcrumbItem>Meus Dados</BreadcrumbItem>
            <BreadcrumbItem active>Meus Pedidos</BreadcrumbItem>
          </Breadcrumb>
        </Container>
      </Container>

      <Container className="container-content">
        <Row>
          <Col>
            <h2>Meus Pedidos</h2>

            <h3>Histórico</h3>

            <div className="orders-list__items">
              {Object.keys(orders).length >= 1 &&
                orders.map((order, index) => {
                  let products = order.order_products;
                  let imageStore = null;

                  if (order.company.image) {
                    imageStore = JSON.parse(order.company.image);
                  }

                  return (
                    <div className="orders__item" key={index}>
                      <div className="order-card">
                        <div className="order-card-finished">
                          <div className="order-card-finished__merchant">
                            <div className="order-merchant">
                              <div className="order-merchant__logo">
                                {imageStore && (
                                  <img
                                    src={imageStore.path}
                                    alt={order.company.name}
                                    title={order.company.name}
                                  />
                                )}
                              </div>

                              <div className="order-merchant__details-wrapper">
                                <div className="order-merchant__details">
                                  <div className="order-merchant__restaurant-name">
                                    {order.company.name}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="order-card-finished__items">
                            {products &&
                              products.map((product) => {
                                return (
                                  <div className="item">
                                    <div className="item-qtd-wrapper">
                                      <div className="item-qtd-text">
                                        {product.amount}
                                      </div>
                                    </div>

                                    <p>{product.products.name}</p>
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}
