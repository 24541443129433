import { useEffect, useState } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { useLocation } from "react-router-dom";

// Components
import HeaderHome from "../../components/HeaderHome";
import StoreCategory from '../../components/StoreCategory';

// Service
import companyCategoryService from "../../services/companyCategories";

// Style
import "./style.scss";

const Category = () => {
  const location = useLocation();
  const pathname = location.pathname;

  const [categories, setCategories] = useState([]);

  useEffect(() => {
    companyCategoryService
      .findAll()
      .then((res) => setCategories(res.data))
      .catch((err) => console.log(err));
  }, []);

  return (
    <Container>
      <div className="store-body">
        <div className="store-filter">
          <HeaderHome pathname={pathname} />
        </div>

        <Row>
          <Col xs={12} md={12}>
            <div className="store-category">
              {categories &&
                categories.map((category) => {
                  return (
                    <StoreCategory category={category}/>
                  );
                })}
            </div>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default Category;
