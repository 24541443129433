import { useState } from "react";
import {
  Alert,
  Button,
  Container,
  Col,
  Form,
  Row,
  Breadcrumb,
} from "react-bootstrap";
import { Facebook } from "react-bootstrap-icons";
import * as yup from "yup";
import { Formik } from "formik";
import { useNavigate } from "react-router";
import FacebookLogin from "react-facebook-login";

import MeService from "../../services/me";

const schema = yup.object().shape({
  email: yup.string().email().required("Email é obrigatório"),
  password: yup.string().required("Senha é obrigatório"),
});

const Login = () => {
  const navigate = useNavigate();

  const [show, setShow] = useState(false);

  const responseFacebook = async (response) => {
    if (response.accessToken) {
      const fbResponse = await MeService.facebookRegister(response.name, response.email, response.id);

      if (fbResponse) {
        console.log(fbResponse)
        sessionStorage.setItem("authenticate", fbResponse.data.authenticate);
        sessionStorage.setItem("user", JSON.stringify(fbResponse.data.client));

        sessionStorage.setItem("token", fbResponse.data.token);

        navigate("/");
      }
    }
  };

  return (
    <>
      <Container fluid className="breadcrumb-container">
        <Container>
          <Breadcrumb>
            <Breadcrumb.Item href="/">Início</Breadcrumb.Item>
            <Breadcrumb.Item active>Entrar</Breadcrumb.Item>
          </Breadcrumb>
        </Container>
      </Container>

      <Container>
        <div className="container-content">
          <Row>
            <Col md={12}>
              <h2>Entrar</h2>
            </Col>
          </Row>

          <Row>
            <Col md={6}>

              <p>Para acessar entre com e-mail e senha</p>

              {show && (
                <Alert
                  variant="danger"
                  onClose={() => setShow(false)}
                  dismissible
                >
                  <Alert.Heading>
                    Oh não! Alguma coisa deu errado!
                  </Alert.Heading>
                  <p>Email ou senha inválidos, tente novamente</p>
                </Alert>
              )}

              <Formik
                initialValues={{
                  email: "",
                  password: "",
                }}
                onSubmit={(values) => {
                  MeService.auth(values.email, values.password).then((res) => {
                    if (res.data.authenticate) {
                      sessionStorage.setItem(
                        "authenticate",
                        res.data.authenticate
                      );
                      sessionStorage.setItem(
                        "user",
                        JSON.stringify(res.data.client)
                      );

                      sessionStorage.setItem("token", res.data.token);

                      navigate("/");
                    } else {
                      setShow(true);
                    }
                  });
                }}
                validationSchema={schema}
              >
                {({
                  handleSubmit,
                  handleChange,
                  values,
                  touched,
                  errors,
                  isValid,
                }) => (
                  <Form noValidate onSubmit={handleSubmit}>
                    <Form.Group className="mb-3" controlId="formLoginEmail">
                      <Form.Label>
                        E-mail <strong>*</strong>
                      </Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Email"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        isValid={touched.email && !errors.email}
                        isInvalid={!!errors.email}
                      />

                      <Form.Control.Feedback type="invalid">
                        {errors.email}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formLoginPassword">
                      <Form.Label>
                        Senha <strong>*</strong>
                      </Form.Label>
                      <Form.Control
                        type="password"
                        placeholder="Senha"
                        name="password"
                        value={values.password}
                        onChange={handleChange}
                        isValid={touched.password && !errors.password}
                        isInvalid={!!errors.password}
                      />

                      <Form.Control.Feedback type="invalid">
                        {errors.password}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Button variant="primary" type="submit" size="lg">
                      Acessar
                    </Button>
                  </Form>
                )}
              </Formik>
            </Col>

            <Col md={6} className="d-flex justify-content-center">
              <FacebookLogin
                appId="157360318293289"
                autoLoad={false}
                fields="name,email,picture"
                textButton=" Entrar com Facebook"
                callback={responseFacebook}
                icon={<Facebook size={30}/>}
              />
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
};

export default Login;
