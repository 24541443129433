import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router";
import { useEffect } from "react";

// estilos
import "./style.scss";

// images
import image from "./3804918.jpg";

const NotFound = () => {
  const navigate = useNavigate();
  const sessionStoreId = sessionStorage.getItem("storeId");

  useEffect(() => {
    if (sessionStoreId) {
      navigate(`/delivery/store/${sessionStoreId}`);
    }
  }, [sessionStoreId, navigate])

  return (
    <Container>
      <Row className="content">
        <Col sm={12} md={12}>
          <h2 className="text-center">Loja não encontrada</h2>

          <div className="image-wrapper">
            <img
              src={image}
              alt="não encontrado"
              title="não encontrado"
              className="image-not-found"
            />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default NotFound;
