import instance from "../axiosInstance";

const auth = (email: string, password: string) => {
  return instance.post(process.env.REACT_APP_API_URL + "/auth", {
    email: email,
    password: password,
  });
};

export const isAuthenticated = () => sessionStorage.getItem("authenticate") !== null;

const authService = {
  auth,
  isAuthenticated,
};

export default authService;
