import { Breadcrumb, Button, Col, Container, Form, Row } from "react-bootstrap";
import { useFormik } from "formik";
import { useNavigate } from "react-router";
import { connect } from "react-redux";
import InputMask from "react-input-mask";
import * as yup from "yup";

// styles
import "./style.scss";

// services
import UserService from "../../services/users";

const User = ({ storeRegister }) => {
  const navigate = useNavigate();

  const schemaValidation = yup.object().shape({
    name: yup.string().required("Obrigatório").max(128),
    email: yup.string().required("Obrigatório").max(128),
    password: yup.string().required("Obrigatório").max(128),
    phone: yup.string().required("Obrigatório").max(128),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      password: "",
      phone: "",
    },
    onSubmit: (values) => {
      const dataUser = {
        name: values.name,
        email: values.email,
        password: values.password,
        phone: values.phone,
        company_id: storeRegister.id,
      };

      UserService.create(dataUser).then((res) => {
        if (res.status === 200) {
          navigate("/partner/success");
        }
      });
    },
    validationSchema: schemaValidation,
  });

  return (
    <>
      <Container fluid className="breadcrumb-container">
        <Container>
          <Breadcrumb>
            <Breadcrumb.Item href="/">Início</Breadcrumb.Item>
            <Breadcrumb.Item active>Seja parceiro</Breadcrumb.Item>
          </Breadcrumb>
        </Container>
      </Container>

      <Container>
        <div className="content">
          <Row>
            <Col sm={12} lg={12}>
              <h1>Falta pouco!</h1>
            </Col>
          </Row>

          <Row>
            <Col sm={12} lg={12}>
              <Form noValidate onSubmit={formik.handleSubmit}>
                <h2>Seus dados</h2>

                <p>Agora queremos conhecer você</p>

                <Form.Group>
                  <Form.Label>Nome</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Nome"
                    name="name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.name && formik.errors.name ? (
                    <div>{formik.errors.name}</div>
                  ) : null}
                </Form.Group>

                <Form.Group>
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Email"
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                  />

                  {formik.touched.email && formik.errors.email ? (
                    <div>{formik.errors.email}</div>
                  ) : null}
                </Form.Group>

                <Form.Group>
                  <Form.Label>Telefone</Form.Label>
                  <InputMask
                    type="text"
                    placeholder="Telefone"
                    name="phone"
                    mask="(99) 99999-9999"
                    className="form-control"
                    onChange={formik.handleChange}
                    value={formik.values.phone}
                  />

                  {formik.touched.phone && formik.errors.phone ? (
                    <div>{formik.errors.phone}</div>
                  ) : null}
                </Form.Group>

                <Form.Group>
                  <Form.Label>Senha</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Senha"
                    name="password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                  />

                  {formik.touched.password && formik.errors.password ? (
                    <div>{formik.errors.password}</div>
                  ) : null}
                </Form.Group>

                <div className="text-center mt-2">
                  <Button variant="primary" size="xl" type="submit">
                    Concluir!
                  </Button>
                </div>
              </Form>
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    storeRegister: state.storeRegister.store,
  };
};

export default connect(mapStateToProps)(User);
