import axios from "axios"

const states = () => {
  const url = process.env.REACT_APP_API_URL + '/location/states'
  return axios.get(url)
}

const cityByState = (stateId) => {
  const url = process.env.REACT_APP_API_URL + '/location/cities/state/' + stateId
  return axios.get(url)
}

const location = {
  cityByState,
  states,
}

export default location
