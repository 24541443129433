import React from "react";
import "./style.scss";

const ContainerItem = (props) => {
  return (
    <div className="container-item">{props.children}</div>
  )
};

export default ContainerItem;
