import React, { useState } from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { useNavigate } from "react-router";
import companiesService from "../../services/companies";

import "./make.scss";

const Make = ({ product }) => {
  const navigate = useNavigate();
  const [cart, setCart] = useState([]);
  const [amount, setAmount] = useState(0);

  const goCheckout = async () => {
    await addItem(product);
  };

  const addItem = async (item) => {
    const companyData = await companiesService.findById(item.company_id);

    let cartCopy = [...cart];

    cartCopy.push({ ...item, amount: amount });

    setCart(cartCopy);

    sessionStorage.setItem("cart", JSON.stringify(cartCopy));
    sessionStorage.setItem("store", JSON.stringify(companyData.data));

    navigate("/delivery/checkout");
  };

  const handleChangeAmount = (op) => {
    if (op === "add") {
      setAmount(amount + 1);
    } else {
      setAmount(amount - 1);
    }
  };

  const handleSetValueAmout = (e) => {
    setAmount(e.target.value);
  };

  return (
    <>
      <Container>
        <Row>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label>Observação</Form.Label>
              <Form.Control
                as="textarea"
                placeholder="Observações do pedido"
                style={{ height: "100px" }}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mt-2">
          <Col xs={6} sm={4} md={4}>
            <div className="input-group spinner-container">
              <span className="input-group-btn">
                <button
                  className="btn"
                  onClick={() => handleChangeAmount("add")}
                >
                  +
                </button>
              </span>

              <input
                type="text"
                className="form-control input-number"
                value={amount}
                onChange={handleSetValueAmout}
              />

              <span className="input-group-btn">
                <button
                  className="btn"
                  onClick={() => handleChangeAmount("sub")}
                >
                  -
                </button>
              </span>
            </div>
          </Col>
          <Col xs={6} sm={4} md={8}>
            <Button variant="primary" onClick={() => goCheckout()}>
              Adicionar
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Make;
