import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ContainerItem from "../../components/ContainerItem";

import "./progress.scss";

const Progress = () => {
  return (
    <Container>
      <Row>
        <Col md={6}>
          <h2>
            <strong>Pedido em andamento</strong>
          </h2>

          <p className="title-section">Previsão de entrega:</p>
          <ContainerItem>
            <p>
              <strong>Entre 39 - 49 min</strong>
            </p>
            <p>Entrega padrão</p>
          </ContainerItem>

          <p className="title-section">Atualizações do pedido:</p>

          <div className="steps-container">
            <ul className="steps">
              <li className="active">
                <p className="step-title">Pedido realizado</p>
                <span className="step-subtitle">Hoje, 18:00</span>
              </li>
              <li>
                <p className="step-title">Pedido confirmado</p>
                <span>Hoje, 18:02</span>
              </li>
              <li>
                <p className="step-title">Em transito</p>
              </li>
            </ul>
          </div>
        </Col>
        <Col md={6}>
          <ContainerItem>
            <p className="title-resume">Resumo do pedido</p>

            <ul className="order-list">
              <li>
                <strong>Monte seu lanche</strong>
                <ul>
                  <li>Pão parmessão</li>
                  <li>Duplo queijo</li>
                  <li>Batata palha</li>
                  <li>Azeitonas</li>
                  <li>Peito de peru</li>
                </ul>
              </li>
              <li>
                <strong>Suco de morango</strong>
              </li>
              <li>
                <li>Subtotal</li>
                <li>Taxa de entrega</li>
                <li>Total</li>
              </li>
            </ul>
          </ContainerItem>
        </Col>
      </Row>
    </Container>
  );
};

export default Progress;
