import { Container, Col, Form, Row, Button, Breadcrumb } from "react-bootstrap";
import { useNavigate } from "react-router";
import { useEffect, useState } from "react";

// styles
import "./style.scss";

// svg
import { ReactComponent as SadEmoji } from "../../assets/sadEmoji.svg";

// services
import CartService from "../../services/cart";
import ClientService from "../../services/clients";
import MeService from "../../services/me";
import OrderService from "../../services/orders";
import authService from "../../services/auth";

const Checkout = () => {
  const navigate = useNavigate();
  const [payMethod, setPayMethod] = useState(0);
  const [address, setAddress] = useState([]);
  const [addressSelected, setAddressSelected] = useState({});

  // local storage
  const items = JSON.parse(sessionStorage.getItem("cart"));
  const store = JSON.parse(sessionStorage.getItem("store"));
  const user = JSON.parse(sessionStorage.getItem("user"));

  let total = 0;

  const submitOrder = async () => {
    if (!ClientService.geAuthenticate()) {
      navigate("/login");
      return;
    }

    const productsInsert = items.map((product) => {
      return {
        productId: product.id,
        price: product.price,
        amount: product.amount,
        observation: "teste",
      };
    });

    const dataOrder = {
      clientId: user.id,
      companyId: store.id,
      products: productsInsert,
      paymethodId: payMethod,
      orderStatusId: 1,
      clientAddressId: addressSelected.id,
    };

    await OrderService.create(dataOrder);

    CartService.cleanCart();

    navigate("/delivery/finished");
  };

  const handleRemoveCheckout = (indexItem) => {
    const newObj = items.filter((value, index) => index !== indexItem ?? value);

    if (Object.keys(newObj).length > 0) {
      sessionStorage.setItem("cart", JSON.stringify([newObj]));
      navigate(0);
    } else {
      sessionStorage.removeItem("cart");
      navigate("/");
    }
  };

  // link para adicionar um novo endereço
  const handleAddNewAddress = () => {
    if (!ClientService.geAuthenticate()) {
      navigate("/login");
      return;
    }

    return navigate("/client/add-address");
  };

  // address
  useEffect(() => {
    if (authService.isAuthenticated()) {
      MeService.list().then((res) => setAddress(res.data));
    }
  }, []);

  return (
    <>
      <Container fluid className="breadcrumb-container">
        <Container>
          <Breadcrumb>
            <Breadcrumb.Item href="/">Início</Breadcrumb.Item>
            <Breadcrumb.Item active>Finalizar</Breadcrumb.Item>
          </Breadcrumb>
        </Container>
      </Container>

      {items && (
        <Container>
          <Row className="content">
            <Col sm={12} md={12}>
              <h2>{store.name}</h2>
            </Col>
          </Row>

          <Row className="content">
            <Col>
              <ul className="checkout-items">
                {items.map((item, index) => {
                  total += item.amount * item.price;
                  return (
                    <li key={index}>
                      <div className="checkout-item">
                        <span className="checkout-item-name">
                          {item.amount}x - {item.name}
                        </span>
                        <span className="checkout-item-description">
                          {item.description}
                        </span>
                        <span className="checkout-item-price">
                          {new Intl.NumberFormat("pt-BR", {
                            style: "currency",
                            currency: "BRL",
                          }).format(item.amount * item.price)}
                        </span>
                        <Button
                          onClick={() => handleRemoveCheckout(index)}
                          variant="danger"
                          size="sm"
                        >
                          Remover
                        </Button>
                      </div>
                    </li>
                  );
                })}
              </ul>
              <div className="checkout-subtotal">
                <div className="checkout-subtotal-label">
                  <span>Total</span>
                </div>

                <div className="checkout-subtotal-total">
                  <span>
                    {new Intl.NumberFormat("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    }).format(total)}
                  </span>
                </div>
              </div>
            </Col>
          </Row>

          <div className="content">
            <Row>
              <Col md={12}>
                <h2>Forma de pagamento</h2>

                <Form.Check
                  type="radio"
                  id="dinheiro"
                  label="Dinheiro"
                  value="dinheiro"
                  name="pay-method"
                  onChange={() => setPayMethod(1)}
                />

                <Form.Check
                  type="radio"
                  id="debito"
                  label="Débito"
                  value="debito"
                  name="pay-method"
                  onChange={() => setPayMethod(2)}
                />

                <Form.Check
                  type="radio"
                  id="credito"
                  label="Crédito"
                  value="credito"
                  name="pay-method"
                  onChange={() => setPayMethod(3)}
                />
              </Col>
            </Row>
          </div>

          <div className="content">
            <Row>
              <Col md={12}>
                <h2>Endereço de entrega</h2>

                <Button onClick={handleAddNewAddress} name="add-new-address">
                  Adicionar endereço
                </Button>

                {address && address.map((adrs, adrsIndex) => {
                    return (
                      <div key={adrsIndex}>
                        <Form.Check
                          type="radio"
                          label={adrs.name}
                          value={adrs.id}
                          name={address}
                          onChange={() => setAddressSelected(adrs)}
                        />
                      </div>
                    );
                  })}
              </Col>
            </Row>
          </div>

          <div className="content">
            <Row>
              <Col md={12} className="d-flex justify-content-center">
                <Button
                  variant="primary"
                  size="lg"
                  onClick={() => submitOrder()}
                >
                  Finalizar
                </Button>
              </Col>
            </Row>
          </div>
        </Container>
      )}

      {!items && (
        <Container>
          <Row className="content">
            <Col md={12} className="text-center">
              <SadEmoji />
              <p className="mt-3">Seu carrinho está vazio!</p>
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
};

export default Checkout;
