import { Container, Row, Col } from "react-bootstrap";
import Check from "../../assets/tick-green-icon.svg";

const Finished = () => {
  return (
    <>
      <Container>
        <div className="container-content">
          <Row>
            <Col>
              <h2>Pedido confirmado</h2>

              <div className="text-center my-4">
                <img
                  src={Check}
                  alt="confirmado"
                  title="confirmado"
                  width={48}
                />
              </div>

              <p className="text-center">
                Agora só esperar o seu pedido ficar pronto
              </p>
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
};

export default Finished;
