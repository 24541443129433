import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import companiesService from "../../services/companies";
import "./style.scss";
import { setStoreDefault } from "../../store/actions/store";
import HeaderHome from "../../components/HeaderHome";

const storeEnable = (store, definirLoja) => {
  let imageStore = null;

  if (store.image) {
    imageStore = JSON.parse(store.image);
  }

  if (store.store) {
    return (
      <>
        <Link to={`/delivery/name/${store.slug}`} className="store-list-title" onClick={() => definirLoja(store)}>
          <div className="store" key={store.id}>
            {imageStore && (
              <div className="store-img ">
                <img src={imageStore.path} alt={store.name} className="p-2 rounded-circle" />
              </div>
            )}

            <div className="story-body">
              <span className="store-item-title">
                <Link to={`/delivery/name/${store.slug}`} className="store-list-title" onClick={() => definirLoja(store)}>
                  {store.name}
                </Link>
              </span>

              <div className="btn-order-now">
                <Link to={`/delivery/name/${store.slug}`} onClick={() => definirLoja(store)}>
                  Fazer pedido
                </Link>
              </div>

              {store.description && (
                <span className="store-description">
                  {store.description.slice(0, 80)} {store.description.length >= 80 && "..."}
                </span>
              )}
            </div>
          </div>
        </Link>
      </>
    );
  } else if (!store.store && store.phone) {
    const urlExternal = 'https://api.whatsapp.com/send/?phone=' + store.phone + '&text=oi'

    return (
      <>
        <a href={urlExternal} className="store-list-title" target="_blank" rel="noreferrer">
          <div className="store" key={store.id}>
            {imageStore && (
              <div className="store-img">
                <img src={imageStore.path} alt={store.name} className="p-2 rounded-circle" />
              </div>
            )}

            <div className="store-body">
              <span className="store-item-title">
                <a href={urlExternal} className="store-list-title" target="_blank" rel="noreferrer">
                  {store.name}
                </a>
              </span>

              <div className="btn-order-now">
                <a href={urlExternal} target="_blank" rel="noreferrer">
                  Fazer pedido
                </a>
              </div>

              {store.description && (
                <span className="store-description">
                  {store.description.slice(0, 80)} {store.description.length >= 80 && "..."}
                </span>
              )}
            </div>
          </div>
        </a>
      </>
    );
  } else {
    return <span>{store.name}</span>
  }
};

const Home = (props) => {
  const location = useLocation();

  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  const [stores, setStores] = useState([]);
  const query = useQuery();
  const pathname = location.pathname;
  const category = query.get("category");
  const queryStore = query.get("store");

  useEffect(() => {
    if (category) {
      companiesService.findByCategory(category).then((res) => {
        setStores(res.data);
      });
    } else if (queryStore) {
      companiesService.findByName(queryStore).then((res) => {
        setStores(res.data);
      });
    } else {
      companiesService.listHome().then((res) => {
        setStores(res.data);
      });
    }
  }, [category, queryStore]);

  return (
    <>
      <Container>
        <div className="store-body">
          <div className="store-filter">
            <HeaderHome pathname={pathname} />
          </div>
          <Row>
            <Col md={12}>
              <div className="store-list">
                {stores && stores.map((store) => storeEnable(store, props.definirLoja))}
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    store: state.store,
  };
};

const mapDispatchToProp = (dispatch) => {
  return {
    definirLoja(loja) {
      const action = setStoreDefault(loja);
      dispatch(action);
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProp)(Home);
