import { useState } from "react";
import { useFormik } from "formik";
import {
  Form,
  Button,
  Alert,
  Container,
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
} from "react-bootstrap";
import { useNavigate } from "react-router";
import InputMask from "react-input-mask";

// services
import ClientService from "../../services/clients";

// scheme validation
import clientValidationSchema from "../../validation/client";

const messageError = (errors) => {
  const itemsErrors = errors.map((item) => <p>{item.msg}</p>);

  if (errors.length > 0) {
    return (
      <Alert variant="danger">
        <h2>Ops...</h2>
        {itemsErrors}
      </Alert>
    );
  }
};

export default function Account() {
  const navigate = useNavigate();
  const [errors, setErrors] = useState([]);

  const formik = useFormik({
    initialValues: {
      name: "",
      phone: "",
      email: "",
      password: "",
    },
    onSubmit: (values) => {
      ClientService.create(values).then(({ data }) => {
        if (data && data.errors) {
          setErrors(data.errors);
        }

        if (data && data.id) {
          navigate("/success-account");
        }
      });
    },
    validationSchema: clientValidationSchema.withPasswordSchema,
  });

  return (
    <>
      <Container fluid className="breadcrumb-container">
        <Container>
          <Breadcrumb>
            <BreadcrumbItem>Home</BreadcrumbItem>
            <BreadcrumbItem active>Criar Conta</BreadcrumbItem>
          </Breadcrumb>
        </Container>
      </Container>

      <Container>
        <div className="content">
          <Row>
            <Col md={12}>
              <h2>Criar conta</h2>

              <p>
                Você está a um passo de ter sua conta é muito simples e prático
              </p>

              {messageError(errors)}
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <Form onSubmit={formik.handleSubmit}>
                <Form.Group className="mb-3" controlId="name">
                  <Form.Label>Nome</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    onChange={formik.handleChange}
                    value={formik.values.name}
                    isInvalid={formik.touched.name && formik.errors.name}
                  />

                  {formik.touched.name && formik.errors.name ? (
                    <span className="invalid-feedback">
                      {formik.errors.name}
                    </span>
                  ) : null}
                </Form.Group>

                <Form.Group className="mb-3" controlId="phone">
                  <Form.Label>Celular</Form.Label>
                  <InputMask
                    type="text"
                    name="phone"
                    mask="(99) 99999-9999"
                    className="form-control"
                    onChange={formik.handleChange}
                    value={formik.values.phone}
                    isInvalid={formik.touched.phone && formik.errors.phone}
                  />

                  {formik.touched.phone && formik.errors.phone ? (
                    <span className="invalid-feedback">
                      {formik.errors.phone}
                    </span>
                  ) : null}
                </Form.Group>

                <Form.Group className="mb-3" controlId="email">
                  <Form.Label>E-mail</Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    onChange={formik.handleChange}
                    value={formik.values.email}
                    isInvalid={formik.touched.email && !!formik.errors.email}
                  />

                  <Form.Control.Feedback type="invalid">
                    {formik.errors.email}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-3" controlId="password">
                  <Form.Label>Senha</Form.Label>
                  <Form.Control
                    type="password"
                    name="password"
                    onChange={formik.handleChange}
                    value={formik.values.password}
                    isInvalid={formik.touched.password && !!formik.errors.password}
                  />

                  <Form.Control.Feedback type="invalid">
                    {formik.errors.password}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Button
                    variant="primary"
                    type="submit"
                    size="lg"
                    block
                    className="mt-2 float-end"
                  >
                    Criar conta!
                  </Button>
                </Form.Group>
              </Form>
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
}
