import instance from "../axiosInstance"

const url = process.env.REACT_APP_API_URL + "/categories";

const findAll = () => instance.get(url)

const findByCompany = (company) => instance.get(url + "/company/" + company)

const findOne = (id) => instance.get(url + "/" + id)

const products = (company) => instance.get(url + '/company/' + company + '/products')

const categoryService = {
  findAll,
  findByCompany,
  findOne,
  products
}

export default categoryService
