import React from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap'
import HeaderStore from '../../components/HeaderStore'
import ProductItem from '../../components/ProductItem'

function Cart() {
  const store = {
    name: 'Old West Premium'
  }

  const items = [
    {
      name: 'Buffalo',
      description: 'Pão, burguer 200g',
      price: 16.90,
      image: 'https://via.placeholder.com/90'
    },
    {
      name: 'Durango',
      description: 'Pão, burguer 200g',
      price: 19.40,
      image: 'https://via.placeholder.com/90'
    }
  ]

  return (
    <>
      <HeaderStore store={store} />
      <Container>
        <Row>
          <Col>
            <ProductItem items={items} />
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="d-flex justify-content-around">
              <span className="title">SubTotal</span>
              <span className="price">R$ 32,80</span>
            </div>

            <div className="d-flex justify-content-around">
              <span className="title">Taxa de entrega</span>
              <span className="price">R$ 6,99</span>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button variant="primary" size="lg" block>
              <div className="d-flex justify-content-around">
                <span>Fechar pedido</span>
                <span>R$ 38,30</span>
              </div>
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default Cart
