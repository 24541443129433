import { useNavigate } from "react-router";
import { Analytics } from '@vercel/analytics/react';

// components
import Footer from "../Footer";
import Header from "../Header";

export interface LayoutProps {
  children: React.ReactNode;
}

const Layout = (props: LayoutProps) => {
  const navigate = useNavigate();

  const handleFindStore = (store) => {
    navigate('/?store=' + store);
  };

  return (
    <>
      <Header handleFindStore={handleFindStore} />
      <main>{props.children}</main>
      <Footer />
      <Analytics />
    </>
  );
};

export default Layout;
