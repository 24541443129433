const initialState = {
  store: {},
}

const storeRegisterReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_STORE_REGISTER":
      return {
        ...state,
        store: action.payload
      }

    case "GET_STORE_REGISTER":
      return state.store;

    default:
      return state;
  }
}

export default storeRegisterReducer;
